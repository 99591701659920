import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import StudentFilter from '../../containers/filter/student-filter';
import moment from 'moment';
import './table.css';
import './overview-user-table.css';
import userImage from '../../images/user/user-image.png';
import { StudentInfo } from '../../store/approval/types';

interface OverviewUserTableProps {
    selectedUserId: number | null;
    setSelectedUserId: (id: number | null) => void;
    roomId: string | null;
}



const OverviewUserTable: React.FC<OverviewUserTableProps> = ({ selectedUserId, setSelectedUserId, roomId }) => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const [searchQuery, setSearchQuery] = useState('');
    const [nameSortDir, setNameSortDir] = useState<'asc' | 'desc' | null>('asc');
    const [registerDateSortDir, setRegisterDateSortDir] = useState<'asc' | 'desc' | null>(null);



    const handleRowClick = (userId: number) => {
        if (userId !== selectedUserId) {
            setSelectedUserId(userId);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const filterUsers = (user: StudentInfo, searchQuery: string, roomId: string | null) => {
        const lowerCaseSearchTerm = searchQuery.toLowerCase();
        const fieldsToSearch = [user.id?.toString(), user.fullname, user.login];
        return fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm)) && user.room_id === roomId;
    };

    const sortByName = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const nameA = a.fullname?.toLowerCase() || '';
        const nameB = b.fullname?.toLowerCase() || '';
        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    };

    const sortByCreateTime = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.create_time ? moment(a.create_time) : moment(0);
        const bDate = b.create_time ? moment(b.create_time) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const handleSortName = () => {
        setNameSortDir(nameSortDir === 'asc' ? 'desc' : 'asc');
        setRegisterDateSortDir(null);
    };

    const handleSortRegisterDate = () => {
        setRegisterDateSortDir(registerDateSortDir === 'asc' ? 'desc' : 'asc');
        setNameSortDir(null);
    };

    const formatDate = (date: moment.MomentInput) => date ? moment(date).format('D MMM, YY') : '—';



    useEffect(() => { dispatch(fetchApprovedListRequest()); }, [dispatch]);



    const sortedList = approvedList.data
        ? approvedList.data
            .filter(user => filterUsers(user, searchQuery, roomId))
            .sort((a, b) => {
                if (nameSortDir) return sortByName(a, b, nameSortDir);
                return 0;
            })
            .sort((a, b) => {
                if (registerDateSortDir) return sortByCreateTime(a, b, registerDateSortDir);
                return 0;
            })
        : [];

    return (
        <div className="overview-user-table-container">
            <div className="filter-container">
                <input
                    type="text" placeholder="Search by id or name" className="livestream-table-search-box"
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                />
                <StudentFilter />
            </div>

            <table className="table-container overview-user-table">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="livestream-table-name" onClick={handleSortName}>
                            Name {nameSortDir !== null ? (nameSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="livestream-table-centered">Nickname</th>
                        <th className="livestream-table-centered">Grade</th>
                        <th className="user-table-center-nowrap" onClick={() => { handleSortRegisterDate() }}>
                            Register Date {registerDateSortDir !== null ? (registerDateSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.length > 0 ? (
                        sortedList.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const userId = Number(user.id);
                            const isSelected = selectedUserId === userId;
                            return (
                                <tr
                                    key={user.id}
                                    className={`overview-user-table-row ${isSelected ? 'selected' : ''}`}
                                    onClick={() => handleRowClick(userId)}
                                >
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td>
                                        {user.fullname}
                                    </td>
                                    <td className={`livestream-table-centered`}>
                                        {nickname}
                                    </td>
                                    <td className="livestream-table-centered">
                                        {mapGrade(user.grade)}
                                    </td>
                                    <td className="livestream-table-centered">
                                        {formatDate(user.create_time)}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={3} className="no-playlists">No Users Available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OverviewUserTable;