import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import userImage from '../../images/user/user-image.png';



const BannedTable = () => {

    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const filteredList = approvedList.filter(item => item.state !== 0);

    return (
        <div>
            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th>Name</th>
                        <th className="user-table-center-nowrap">Nickname</th>
                        <th className="user-table-center-nowrap">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredList.map((user) => (
                        <tr key={user.id}>
                            <td className="user-table-center-nowrap">
                                <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                {user.id}
                            </td>
                            <td>{user.fullname}</td>
                            <td className="user-table-center-nowrap">{user.login}</td>
                            <td className="user-table-center-nowrap">{user.parentPhone}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BannedTable;
