import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import { FirebaseContext } from '../../firebase/Firebase';
import StudentFilter from '../../containers/filter/student-filter';
import ModalHomework from './homework-modal';
import ModalLiveQuiz from './livestream-modal';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';
import './table.css';
import './livestream-table.css'
import userImage from '../../images/user/user-image.png';

interface LivestreamTableProps {
    liveId: number;
    subject: string;
    startTime: string;
    roomId: string | null;
}



const LivestreamTable: React.FC<LivestreamTableProps> = ({ liveId, subject, startTime, roomId }) => {

    const { database: firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [attendanceSet, setAttendanceSet] = useState<Set<string>>(new Set());
    const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean }>({});
    const [isHomeworkModalOpen, setIsHomeworkModalOpen] = useState(false);
    const [isLivestreamModalOpen, setIsLivestreamModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [loading, setLoading] = useState<boolean>(true);
    const [modalFullname, setModalFullName] = useState<string>('');
    const [modalUserId, setModalUserId] = useState<string>('');

    const sortedList = approvedList.data
        ? [...approvedList.data]
            .filter((user) => {
                const lowerCaseSearchTerm = searchQuery.toLowerCase();
                const fieldsToSearch = [user.id?.toString(), user.fullname, user.login];
                return (
                    fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm)) &&
                    user.room_id === roomId
                );
            })
            .sort((a, b) => a.fullname.localeCompare(b.fullname))
        : [];

    const { token } = loginState.data;

    if (!firebase) throw new Error('Database is not available');



    const mapIsPremium = (is_premium: number): string => is_premium === 1 ? 'Premium' : 'Free';

    const formatDate = (expireTime: moment.MomentInput) => expireTime ? moment(expireTime).format('D MMM, YY') : '—';

    const handleCheckboxChange = async (userId: string, currentPremiumPlus: number) => {
        const newPremiumPlus = currentPremiumPlus === 1 ? 0 : 1;
        setCheckboxStates(prevStates => ({ ...prevStates, [userId]: newPremiumPlus === 1 }));
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/toggle-premium-plus`, token, { userId, currentPremiumPlus });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error toggling Premium Plus:', error);
        }
    };

    const fetchAttendance = async (ref: firebase.database.Reference) => {
        try {
            setLoading(true);
            const snapshot = await ref.orderByKey().once('value');
            const firebaseData = snapshot.val() || {};
            const firebaseAttendanceSet = new Set<string>();

            Object.entries(firebaseData).forEach(([, vals]) => {
                const value = vals as { Id: number; };
                const userId = value.Id.toString();
                firebaseAttendanceSet.add(userId);
            });

            const attendanceResponse = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/attendance/live-quiz/${liveId}`, token);
            const apiAttendanceSet = new Set<string>(attendanceResponse);
            const mergedAttendanceSet = new Set<string>([...firebaseAttendanceSet, ...apiAttendanceSet]);
            setAttendanceSet(mergedAttendanceSet);

        } catch (error) {
            console.error("Error fetching attendance data:", error);
        } finally {
            setLoading(false);
        }
    };

    const openLivestreamModal = (fullname: string, userId: string) => {
        setModalFullName(fullname); setModalUserId(userId); setIsLivestreamModalOpen(true);
    };

    const closeLivestreamModal = () => {
        setIsLivestreamModalOpen(false); setModalFullName(''); setModalUserId('');
    };

    const openHomeworkModal = (fullname: string, userId: string) => {
        setModalFullName(fullname); setModalUserId(userId); setIsHomeworkModalOpen(true);
    };

    const closeHomeworkModal = () => {
        setIsHomeworkModalOpen(false); setModalFullName(''); setModalUserId('');
    };

    const getExpireTimeClass = (expireTime: moment.MomentInput) => {
        if (!expireTime) return '';

        const expirationDate = moment(expireTime);
        const now = moment();
        const twoWeeksFromNow = moment().add(2, 'weeks');

        if (expirationDate.isBefore(now)) {
            return 'expire-red';
        } else if (expirationDate.isBefore(twoWeeksFromNow)) {
            return 'expire-yellow';
        }
        return '';
    };



    useEffect(() => {
        fetchAttendance(firebase.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${liveId}/`));
    }, [liveId]);

    useEffect(() => {
        dispatch(fetchApprovedListRequest());
    }, [dispatch]);



    return (
        <>
            <div className="filter-container">
                <input
                    type="text" placeholder="Search by id or name" className="livestream-table-search-box"
                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                />
                <StudentFilter />
            </div>

            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="livestream-table-name">Name</th>
                        <th className="livestream-table-centered">Nickname</th>
                        <th className="livestream-table-centered">Grade</th>
                        <th className="livestream-table-centered">Type</th>
                        <th className="livestream-table-centered">Exp. Date</th>
                        <th className="livestream-table-centered">Premium Plus</th>
                        <th className="livestream-table-centered">Attendance</th>
                        <th className="livestream-table-centered">Live Quiz</th>
                        <th className="livestream-table-centered">Homework</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.length > 0 ? (
                        sortedList.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            return (
                                <tr key={user.id}>
                                    <td className="livestream-table-centered">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td>
                                        {user.fullname}
                                    </td>

                                    <td className="livestream-table-centered">
                                        {nickname}
                                    </td>

                                    <td className="livestream-table-centered">
                                        {mapGrade(user.grade)}
                                    </td>

                                    <td className="livestream-table-centered">
                                        {mapIsPremium(user.is_premium)}
                                    </td>

                                    <td className="livestream-table-centered">
                                        <span className={getExpireTimeClass(user.expire_time)}>
                                            {formatDate(user.expire_time)}
                                        </span>
                                    </td>

                                    <td className="livestream-table-centered">
                                        <input
                                            type="checkbox"
                                            checked={checkboxStates[user.id] !== undefined ? checkboxStates[user.id] : user.premium_plus === 1}
                                            onChange={() => handleCheckboxChange(user.id, user.premium_plus)}
                                        />
                                    </td>

                                    <td className={`livestream-table-centered attendance-${attendanceSet.has(user.id.toString()) ? 'present' : 'absent'}`}>
                                        {loading ? <PulseLoader color="#0b7aff" /> : (attendanceSet.has(user.id.toString()) ? 'Present' : 'Absent')}
                                    </td>

                                    <td className="livestream-table-centered">
                                        <button className="live-modal-view-button" onClick={() => openLivestreamModal(user.fullname, user.id.toString())}>
                                            View
                                        </button>
                                    </td>

                                    <td className="livestream-table-centered">
                                        <button className="live-modal-view-button" onClick={() => openHomeworkModal(user.fullname, user.id.toString())}>
                                            View
                                        </button>
                                    </td>

                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={8} className="no-playlists">No Users Available</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <ModalLiveQuiz
                isOpen={isLivestreamModalOpen} onClose={closeLivestreamModal}
                fullname={modalFullname} userId={modalUserId} liveId={liveId}
                subject={subject} startTime={startTime}
            />

            <ModalHomework
                isOpen={isHomeworkModalOpen} onClose={closeHomeworkModal}
                fullname={modalFullname} userId={modalUserId} liveId={liveId}
                subject={subject} startTime={startTime}
            />

        </>
    );
};

export default LivestreamTable;
