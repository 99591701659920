import React from 'react';
import { useSelector } from 'react-redux';
import UserTable from '../../components/table/user-table';
import BannedTable from '../../components/table/banned-table';
import ExpirationTable from '../../components/table/expiration-table';
import PlaylistTable from '../../components/table/playlist-table';
import { ApplicationState } from '../../store';
import moment from 'moment';
import './index.css';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';



const Dashboard = () => {

    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);

    const hasBannedUsers = approvedList.some(user => user.state !== 0);
    const hasExpiringUsers = approvedList.some(user => {
        const twoWeeksFromNow = moment().add(2, 'weeks');
        return (
            (moment(user.expire_time).isBefore(twoWeeksFromNow)) ||
            (moment(user.expire_date).isBefore(twoWeeksFromNow))
        );
    });



    return (
        <div className='content'>

            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Current Users</h2>
                <UserTable />
            </div>

            {hasExpiringUsers && (
                <div className='w-100 mb-5'>
                    <h2 className='dashboard-header'>Expiration Pending Users</h2>
                    <ExpirationTable />
                </div>
            )}

            {hasBannedUsers && (
                <div className='w-100 mb-5'>
                    <h2 className='dashboard-header'>Banned Users</h2>
                    <BannedTable />
                </div>
            )}

            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Current Playlists</h2>
                <PlaylistTable />
            </div>

            <div className='dashboard-logo-image-container'>
                <img src={logoGrey} srcSet={`${logoGrey2x} 2x, ${logoGrey3x} 3x`} alt='' />
            </div>
        </div>
    );
};

export default Dashboard;
