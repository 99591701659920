import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { deleteStudentRequest } from '../../store/approval/actions';
import { StudentInfo } from '../../store/approval/types';
import moment from 'moment';
import userImage from '../../images/user/user-image.png';



const ExpirationTable = () => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const [expandedNames, setExpandedNames] = useState<Set<string>>(new Set());
    const [expandedNicknames, setExpandedNicknames] = useState<Set<string>>(new Set());

    const filteredList = approvedList.filter(user => {
        const twoWeeksFromNow = moment().add(2, 'weeks');
        return (
            (moment(user.expire_time).isBefore(twoWeeksFromNow)) ||
            (moment(user.expire_date).isBefore(twoWeeksFromNow))
        );
    });



    const getDateClass = (date: moment.MomentInput) =>
        date && moment(date).isBefore(moment()) ? 'expire-red' :
            date && moment(date).isBefore(moment().add(2, 'weeks')) ? 'expire-yellow' :
                '';

    const formatDate = (expireTime: moment.MomentInput) => expireTime ? moment(expireTime).format('D MMM, YY') : '—';

    const handleRejectClick = (data: StudentInfo) => {
        const isConfirmed = window.confirm('Are you sure you want to remove this student?');
        if (isConfirmed) dispatch(deleteStudentRequest(data));
    };

    const toggleName = (id: string) => {
        setExpandedNames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const toggleNickname = (id: string) => {
        setExpandedNicknames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };



    return (
        <div>
            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th>Name</th>
                        <th className="user-table-center-nowrap">Nickname</th>
                        <th className="user-table-center-nowrap">Course</th>
                        <th className="user-table-center-nowrap">Premium Exp.</th>
                        <th className="user-table-center-nowrap">Premium<br />Auto Renew</th>
                        <th className="user-table-center-nowrap">Premium Plus Exp.</th>
                        <th className="user-table-center-nowrap">Phone</th>
                        <th className="user-table-center-nowrap">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredList.map((user) => {
                        const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                        const isNameExpanded = expandedNames.has(user.id);
                        const isNicknameExpanded = expandedNicknames.has(user.id);
                        const truncatedName = user.fullname.length > 20 ? `${user.fullname.substring(0, 20)}...` : user.fullname;
                        const truncatedNickname = nickname.length > 20 ? `${nickname.substring(0, 20)}...` : nickname;
                        const isAutoRenewActive = user.renew_date != null && user.renew_date !== '';
                        return (
                            <tr key={user.id}>
                                <td className="user-table-center-nowrap">
                                    <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                    {user.id}
                                </td>
                                <td className="user-table-name">
                                    <span onClick={() => toggleName(user.id)} style={{ cursor: 'pointer' }}>
                                        {isNameExpanded ? user.fullname : truncatedName}
                                    </span>
                                </td>
                                <td className="user-table-center-nowrap">
                                    <span onClick={() => toggleNickname(user.id)} style={{ cursor: 'pointer' }}>
                                        {isNicknameExpanded ? nickname : truncatedNickname}
                                    </span>
                                </td>
                                <td className="user-table-center-nowrap">
                                    {user.room_name}
                                </td>
                                <td className="user-table-center-nowrap">
                                    <span className={`user-table-expiration-date ${getDateClass(user.expire_time)}`}>
                                        {formatDate(user.expire_time)}
                                    </span>
                                </td>
                                <td className="user-table-center-nowrap">
                                    <input className="auto-renew-check" type="checkbox" checked={isAutoRenewActive} disabled />
                                </td>
                                <td className="user-table-center-nowrap">
                                    <span className={`user-table-expiration-date ${getDateClass(user.expire_date)}`}>
                                        {formatDate(user.expire_date)}
                                    </span>
                                </td>
                                <td className="user-table-center-nowrap">{user.parentPhone}</td>
                                <td className="user-table-center-nowrap">
                                    <button className="remove-button" onClick={() => handleRejectClick(user)}>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ExpirationTable;
