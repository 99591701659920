export interface StudentInfo {
    room_id: string
    school_id: string
    id: string
    room_name: string
    room_grade: string
    fullname: string
    login: string
    lowPhotoUrl: string
    normalPhotoUrl: string
    grade: string
    is_premium: number
    create_time: string
    expire_time: string
    premium_plus: number
    address: string
    course: string
    expire_date: string
    lineName: string
    parentPhone: string
    renew_date: string
    state: number
}

export interface PendingList {
    loading: boolean
    data: StudentInfo[] | null
    error: string | null
}

export interface ApprovedList {
    loading: boolean
    data: StudentInfo[] | null
    error: string | null
}

export enum ApprovalActionTypes {
    FETCH_PENDING_LIST_REQUEST = '@@approval/FETCH_PENDING_LIST_REQUEST',
    FETCH_PENDING_LIST_SUCCESS = '@@approval/FETCH_PENDING_LIST_SUCCESS',
    FETCH_PENDING_LIST_ERROR = '@@approval/FETCH_PENDING_LIST_ERROR',

    FETCH_APPROVED_LIST_REQUEST = '@@approval/FETCH_APPROVED_LIST_REQUEST',
    FETCH_APPROVED_LIST_SUCCESS = '@@approval/FETCH_APPROVED_LIST_SUCCESS',
    FETCH_APPROVED_LIST_ERROR = '@@approval/FETCH_APPROVED_LIST_ERROR',

    APPROVE_STUDENT_REQUEST = '@@approval/APPROVE_STUDENT_REQUEST',
    APPROVE_STUDENT_SUCCESS = '@@approval/APPROVE_STUDENT_SUCCESS',
    APPROVE_STUDENT_ERROR = '@@approval/APPROVE_STUDENT_ERROR',

    DELETE_STUDENT_REQUEST = '@@approval/DELETE_STUDENT_REQUEST',
    DELETE_STUDENT_SUCCESS = '@@approval/DELETE_STUDENT_SUCCESS',
    DELETE_STUDENT_ERROR = '@@approval/DELETE_STUDENT_ERROR',

    INCREASE_LIMIT_APPROVED_LIST = '@@approval/INCREASE_LIMIT_APPROVED_LIST',
    SET_TOTAL_APPROVED_LIST = '@@approval/SET_TOTAL_APPROVED_LIST',
}

export interface ApprovalState {
    readonly pendingList: PendingList
    readonly approvedList: ApprovedList
    readonly limitApprovedList: number
    readonly totalApprovedList: number
}
