import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { callApi } from '../../utils/api';
import { Playlist } from '../../store/toggle/types';
import { setPlaylists } from '../../store/toggle/actions';
import PlaylistTableSearch from './playlist-table-search';
import PulseLoader from 'react-spinners/PulseLoader';
import './table.css';
import './playlist-table.css';



const PlaylistTable = () => {

    const dispatch = useDispatch();
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [keyword, setKeyword] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [playlists, setPlaylistsLocal] = useState<Playlist[]>([]);
    const [schoolRooms, setSchoolRooms] = useState<any[]>([]);
    const [searchResults, setSearchResults] = useState<Playlist[]>([]);

    const { token } = loginState.data;



    const getFirstInstructorName = (instructorNames: string) => {
        const match = instructorNames.match(/^([^,]+)/);
        return match ? match[1].trim() : instructorNames;
    };

    const getSchoolPlaylists = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school`, token);
            const processedPlaylists = response.data.map((playlist: Playlist) => ({
                ...playlist, instructor: getFirstInstructorName(playlist.instructor)
            }));
            setPlaylistsLocal(processedPlaylists); dispatch(setPlaylists(processedPlaylists));
        } catch (error) {
            console.error('Error fetching playlists:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/school-room`, token);
            setSchoolRooms(response.schoolRooms);
        } catch (error) {
            console.error('Error fetching school rooms:', error);
        }
    };

    const handleCourses = async (playlistId: string, courseId: string) => {
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/playlist/course/update`, token, { playlistId, courseId });
            await getSchoolPlaylists();
        } catch (err) {
            console.error('Error updating school room ID:', err);
        }
    };

    const searchPlaylists = async () => {
        if (keyword.trim().length < 3) {
            alert('Keyword must be at least 3 characters long.'); return;
        }

        setLoading(true);
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/playlists/search?keyword=${encodeURIComponent(keyword)}`, token);
            const processedPlaylists = response.map((playlist: Playlist) => ({
                ...playlist, instructor: getFirstInstructorName(playlist.instructor)
            }));
            setSearchResults(processedPlaylists);
        } catch (error) {
            console.error('Error searching playlists:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async (id: string) => {
        try {
            const { token } = loginState.data;
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/toggle`, token, { id });
            getSchoolPlaylists(); searchPlaylists();
        } catch (error) {
            console.error('Error adding playlist:', error);
        }
    };

    const handleRemove = async (id: string) => {
        const isConfirmed = window.confirm('Are you sure you want to remove this playlist?');
        if (!isConfirmed) return;
        try {
            const { token } = loginState.data;
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/toggle`, token, { id });
            const updatedPlaylists = playlists.filter(playlist => playlist.id !== id);
            setPlaylistsLocal(updatedPlaylists); dispatch(setPlaylists(updatedPlaylists)); handleClearSearch();
        } catch (error) {
            console.error('Error removing playlist:', error);
        }
    };

    const handleClearSearch = () => {
        setKeyword(''); setSearchResults([]);
    };



    useEffect(() => {
        if (loginState.data) {
            getSchoolPlaylists();
            fetchCourses();
        }
    }, [loginState.data]);



    return (
        <div>
            <table className="table-container">
                <thead>
                    <tr>
                        <th className="table-id">ID</th>
                        <th className="table-instructor">Instructor</th>
                        <th className="table-name">Name</th>
                        <th className="table-name">Course</th>
                        <th className="table-add">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={4}>
                                <div className="loader-container">
                                    <PulseLoader color="#0b7aff" />
                                </div>
                            </td>
                        </tr>
                    ) : playlists.length ? (
                        playlists.map((playlist) => (
                            <tr key={playlist.id}>
                                <td className="table-id">{playlist.id}</td>
                                <td className="table-instructor">{playlist.instructor}</td>
                                <td>{playlist.name}</td>
                                <td className="user-table-center-nowrap">
                                    <select value={playlist.roomId || ''} onChange={(e) => handleCourses(playlist.id, e.target.value)}>
                                        {schoolRooms.map((room) => (
                                            <option key={room.id} value={room.id}>{room.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td className="table-add">
                                    <button className="remove-button" onClick={() => handleRemove(playlist.id)}>Remove</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className="no-playlists">No Playlists Available</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <PlaylistTableSearch
                keyword={keyword} setKeyword={setKeyword}
                searchResults={searchResults} loading={loading}
                searchPlaylists={searchPlaylists} clearSearch={handleClearSearch} handleAdd={handleAdd}
            />
        </div>
    );
};

export default PlaylistTable;
