import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import OverviewTable from '../../components/table/overview-table';
import OverviewUserTable from '../../components/table/overview-user-table';
import './index.css';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';

interface OverviewProps { roomId: string | null; roomName: string | null; }

interface PlaylistDetails { playlistId: string; instructor: string; livestreamIds: string[]; }



const Overview: React.FC<OverviewProps> = ({ roomId, roomName }) => {

    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [loading, setLoading] = useState(true);
    const [playlists, setPlaylists] = useState<PlaylistDetails[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectedWeek, setSelectedWeek] = useState<number | null>(null);

    const { token } = loginState.data;



    const getSchoolPlaylists = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/playlists/${roomId}`, token);
            setPlaylists(response.data);
        } catch (error) {
            console.error('Error fetching playlists:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleButtonClick = (week: number) => setSelectedWeek(week);

    const getLivestreamDataForWeek = (week: number) => {
        return playlists.map(playlist => {
            const livestreamId = playlist.livestreamIds[week - 1];
            return {
                livestreamId: livestreamId !== undefined ? Number(livestreamId) : -1,
                instructor: livestreamId !== undefined ? playlist.instructor : ''
            };
        });
    };



    useEffect(() => { if (loginState.data) { getSchoolPlaylists(); } }, [loginState.data]);

    useEffect(() => {
        if (playlists.length > 0) {
            const maxLivestreamIdsLength = playlists.reduce((max, playlist) => Math.max(max, playlist.livestreamIds.length), 0);
            const weeks = Array.from({ length: maxLivestreamIdsLength }, (_, index) => maxLivestreamIdsLength - index);
            if (weeks.length > 0) setSelectedWeek(weeks[0]);
        }
    }, [playlists]);



    return (
        <div className='content' id="playlistPageContent">
            {loading && <div>Loading...</div>}

            <h3 className="course-name"><strong>Course:</strong> {roomName}</h3>

            <div className="main-layout">
                {playlists.length > 0 ? (
                    <div className="button-container">
                        {Array.from({ length: Math.max(...playlists.map(playlist => playlist.livestreamIds.length)) }, (_, index) => Math.max(...playlists.map(playlist => playlist.livestreamIds.length)) - index)
                            .map((week) => (
                                <button
                                    key={week} onClick={() => handleButtonClick(week)} className={`topic-button ${selectedWeek === week ? 'selected' : ''}`}
                                >
                                    Week {week}
                                </button>
                            ))}
                    </div>
                ) : (
                    !loading && <div>No weeks available.</div>
                )}

                {selectedUserId !== null && roomName && (
                    <OverviewTable
                        selectedUserId={selectedUserId}
                        selectedWeek={selectedWeek}
                        livestreamData={getLivestreamDataForWeek(Number(selectedWeek))}
                        courseName={roomName}
                    />)}

                {selectedWeek && (
                    <OverviewUserTable
                        selectedUserId={selectedUserId}
                        setSelectedUserId={setSelectedUserId}
                        roomId={roomId}
                    />
                )}
            </div>

            <div className='dashboard-logo-image-container'>
                <img src={logoGrey} srcSet={`${logoGrey2x} 2x, ${logoGrey3x} 3x`} alt='' />
            </div>
        </div>
    );
};

export default Overview;
